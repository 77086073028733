<template>
  <div>
    <el-form :model="form" ref="form" :hide-required-asterisk="true">
      <el-form-item
        :label="'Name'"
        prop="name"
        :rules="[{ required: true, message: 'The field is required' }]"
      >
        <el-input type="text" v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item :label="$t('State')" prop="state">
        <el-select
          v-model="form.state"
          placeholder="Select State"
          class="w-100"
        >
          <el-option
            v-for="state in states"
            :key="state.id"
            :label="state.name"
            :value="state.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-button
        type="primary"
        @click="!data ? submitForm('form') : updateForm('form')"
        >Save Register</el-button
      >
    </el-form>
  </div>
</template>

<script>
import form from "@/services/api/form";
import stateSalesTax from "@/services/api/stateSalesTax";

export default {
  props: ["data"],
  data() {
    return {
      states: [],
      form: {
        id: null,
        name: null,
        state: null,
      },
    };
  },
  mounted() {
    stateSalesTax.get().then((response) => {
      this.states = response;
    });

    if (this.data) {
      this.form.id = this.data.id;
      this.form.name = this.data.name;
    }
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          form
            .create(this.form)
            .then((response) => {
              this.$emit("refresh", { data: response, update: false });
            })
            .catch(() => {
              this.$message({
                message: this.$store.getters.message.message,
                type: "error",
                customClass: "message-error",
              });
            });
        } else {
          return false;
        }
      });
    },
    updateForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          form
            .update(this.form)
            .then((response) => {
              this.$emit("refresh", { data: response, update: true });
            })
            .catch(() => {
              this.$message({
                message: this.$store.getters.message.message,
                type: "error",
                customClass: "message-error",
              });
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss"></style>
